import { loadMapBackground, loadMapCustomLayers, loadMapGOLayers, loadMapGOLayersFilter } from 'app/utils/enums';
import {
  MapLayersBackgroundState,
  MapLayersCustomState,
  MapLayersGOFilterState,
  MapLayersGOState,
  MapLayersState
} from 'types';

const defaultMapBackground: MapLayersBackgroundState = {
  administration: false,
  matrikel: false,
  ortofoto: false,
  osm: false,
  topo_fot: true,
  planned: false,
  cvf: false,
};

function getMapBackground(): MapLayersBackgroundState {
  let storedBackground: unknown;
  try {
    storedBackground = loadMapBackground();
  } catch {
    return defaultMapBackground;
  }
  if (storedBackground === null) {
    return defaultMapBackground;
  }
  if (typeof storedBackground === 'string') {
    switch (storedBackground) {
      case 'administration':
      case 'matrikel':
      case 'ortofoto':
      case 'osm':
      case 'topo_fot':
      case 'planned':
        return {
          ...defaultMapBackground,
          [storedBackground]: true,
        };
      default:
        return defaultMapBackground;
    }
  }
  return {
    ...defaultMapBackground,
    ...(typeof storedBackground === 'object' && storedBackground !== null ? storedBackground : {}),
  };
}

function getMapCustomLayers(): MapLayersCustomState {
  const custom: MapLayersCustomState = {};
  const { custom_layers } = FEATURES.map;
  if (custom_layers !== undefined) {
    custom_layers.forEach((layer) => custom[layer.key] = false);
  }
  const storedCustomLayers = loadMapCustomLayers();
  return {
    ...custom,
    ...(storedCustomLayers === null || typeof storedCustomLayers !== 'object' ? {} : storedCustomLayers),
  };
}

function getMapGOLayers(): MapLayersGOState {
  const storedGOLayers = loadMapGOLayers();
  return {
    ...GO.layers,
    ...(storedGOLayers === null || typeof storedGOLayers !== 'object' ? {} : storedGOLayers),
    ...FEATURES.sketching && FEATURES.sketching.enabled
      ? null
      : { sketch: false },
  };
}

function getGOLayersFilter(): MapLayersGOFilterState {
  const storedFilter = loadMapGOLayersFilter();
  return {
    address_count: false,
    address_main: true,
    customer_active: true,
    customer_prospect: true,
    route_inService: true,
    route_outofService: true,
    route_planned: true,
    route_unknown: true,
    zone_container: true,
    zone_project: true,
    zone_sale: true,
    zone_stage: true,
    ...(storedFilter || {}),
  }
}

export function getMapLayers(): MapLayersState {
  return {
    background: getMapBackground(),
    custom: getMapCustomLayers(),
    go: getMapGOLayers(),
    goFilter: getGOLayersFilter(),
    goServiceInfo: {
      allLayers: [],
      symbolRules: {},
    },
    urlCacheBoost: Date.now(),
  };
}
